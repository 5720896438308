<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-10-26 15:08:35
 * @LastEditors: Seven
 * @LastEditTime: 2022-03-08 17:01:07
-->
<template>
  <div v-clickOut="hideDrap" class="gogongMulti">
    <!-- <el-input v-model="select" @click.stop @focus="focus" @clear="deptClear" clearable placeholder="请选择部门" class="textBox"></el-input> -->
    <v-text-field
      v-model="select"
      :label="labelName"
      hide-details="auto"
      outlined
      @mousedown="focus"
      :dense="true"
      :height="32"
      :clearable="true"
      @click:clear="deptClear"
    ></v-text-field>
    <transition name="fade">
      <div
        v-show="IsShowTree"
        style="background-color: #fff;
          position: absolute;
          max-width: 400px;
          min-width: 200px;
          z-index: 111;
          border: 1px solid #eee;
          padding-bottom: 36px;
          height: 350px;"
      >
        <el-tree
          class="multi-tree"
          ref="dept"
          highlight-current
          default-expand-all
          :expand-on-click-node="false"
          :node-key="value"
          :props="defaultProps"
          :filter-node-method="filterNode"
          :data="data"
          show-checkbox
          @node-click="nodeClick"
          @check="checkChange"
          :check-strictly="strictly"
        ></el-tree>
        <div class="buttons">
          <el-button size="small" @click="setCheckedKeys">确认</el-button>
          <el-button size="small" @click="resetChecked">清空</el-button>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "DeptSys-multiSelect",
  props: {
    name: null,
    // 选择框默认文字
    placeholder: {
      type: String,
      default: "请选择"
    },
    label: {
      type: String,
      default: "label"
    },
    value: {
      type: String,
      default: "value"
    },
    children: {
      type: String,
      default: "children"
    },
    // select和tree数据
    data: {
      type: Array,
    },
    strictly: {
      type: Boolean,
      default: false
    },
    labelName: {
      type: String,
    }
  },
  data() {
    return {
      select: "",
      IsShowTree: false,
      selectModel: null,
      // 数据的key value
      defaultProps: {
        children: this.children,
        label: this.label
      },
      menuList: [],
      valArr: [],
    };
  },
  directives: {
    clickOut: {
      bind: (el, binding) => {
        // removeEventListener只能移除对象上的某个具名函数
        function handler(e) {
          if (el.contains(e.target)) return false;
          binding.value();
        }
        el.handler = handler;
        document.addEventListener("click", el.handler);
      },
      unbind: function(el) {
        document.removeEventListener("click", el.handler);
      }
    }
  },
  methods: {
    nodeClick(val) {
    },
    checkChange(val) {
    },
    // 确认
    setCheckedKeys() {
      this.menuList = this.$refs.dept.getCheckedNodes()
      let deptNameArr = []
      let deptIdArr = []
      this.menuList.forEach(item => {
        deptNameArr.push(item.deptName)
        deptIdArr.push(item.deptId)
      })
      this.select = deptNameArr.join(',');
      this.IsShowTree = false;
      this.$emit("update:select", deptIdArr);
      this.$emit("update:name", deptNameArr);
      this.$emit('deptChecked', deptIdArr)
    },

    deptClear() {
      this.reset()
    },

    // 清空
    resetChecked() {
      this.deptClear()
      this.select = ""
    },

    reset() {
       this.$refs.dept.setCheckedKeys([]);
    },

    // 节点过滤
    filterNode(value, data, node) {
      if (!value) return true;
      return data[this.label].indexOf(value) !== -1;
    },
    hideDrap() {
      this.IsShowTree = false;
    },
    focus() {
      this.IsShowTree = true;
    },
    init() {
      this.select = "";
    },
    change(deptName) {
      if (deptName.target.value == "") {
        this.$emit("update:select", "");
        this.$emit("update:name", "");
      }
      var _that = this;
      this.data.list.forEach(element => {
        if (element.deptName === _that.select) {
          _that.nodeClick(element);
          this.$refs.dept.setCurrentKey(element.deptId);
        }
      });
    },
    set(name) {
      this.select = name;
    }
  },
  watch: {
    select (val, oldVal) {
      this.$refs.dept.filter(val);
      if (oldVal && !val) {
        this.$emit('update:select', '')
        this.$emit('update:name', '')
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
    });
  }
};
</script>
<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .buttons{
    position: absolute;
    z-index: 111;
    bottom: 0;
    right: 0;
  }
  .textBox{
    height: 32px!important;
  }
  .gogongMulti >>> .el-input__inner{
    height: 32px;
  }

  .el-tree {
    height: 100%;
    overflow-y: auto;
  }
</style>

